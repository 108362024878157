import React from 'react';
import Image from 'next/image';

import { NextImagePropTypes } from './NextImage.types';
/**
 * NextImage Component
 *
 * An enhanced wrapper around the `next/image` component offering:
 * - Automatic aspect ratio calculation for responsive images.
 * - Overlay capability with child elements on the image.
 *
 * Props:
 * - `src` and `alt`: Standard for any image component.
 * - `children`: Overlay content on top of the image.
 * - `className`: Additional styles for the wrapper.
 * - `aspectRatio`: If provided, the height is automatically calculated based on the width.
 * - `width`: Default to 100. The behavior varies based on `aspectRatio`.
 *
 * Behavior:
 * - With `aspectRatio`, height adjusts based on width.
 * - Without `aspectRatio`, image behaves like a 'cover' CSS background.
 *
 * Available Aspect Ratios (width:height):
 * - 1:1 (Square)
 * - 4:3 (Standard Definition TV)
 * - 3:2 (35mm Film, Digital Cameras)
 * - 16:9 (High Definition TV, Widescreen)
 * - 21:9 (Ultrawide monitors)
 *
 * Best Practices:
 * - For best results, provide the actual dimensions of the used image.
 *   These can be found by inspecting the image or in its details.
 * - Specify width and optionally aspect ratio or height for optimal presentation.
 * - If only `width` is given with `aspectRatio`, `height` is computed. If `aspectRatio` isn't provided, default width is 100.
 *
 * Determining Aspect Ratio:
 * If you've provided the `width` but are unsure about the `aspectRatio`, here are strategies to help determine it:
 *
 * 1. **Inspect Image Metadata**: Check the image's properties on your OS or use image editing software.
 *    Calculate the aspect ratio using: `aspectRatio = originalWidth / originalHeight`.
 *
 * 2. **Visual Approximation**: If precision isn't vital, you can approximate visually. Begin with common aspect ratios
 *    (e.g., 1:1, 16:9, 4:3) and adjust until the image displays satisfactorily.
 *
 * 3. **Use Full Cover**: Without an `aspectRatio`, the image behaves like a background image. It will scale and crop
 *    to fit its container while maintaining its intrinsic aspect ratio.
 *
 * 4. **Responsive Design Tools**: Design software like Figma, Sketch, or Adobe XD often display aspect ratios when
 *    resizing assets.
 *
 * 5. **Calculation Tools**: Online tools exist where you can input width and height to get the aspect ratio. Useful
 *    if you know the image's dimensions but not its ratio.
 *
 * 6. **Browser's Developer Tools**: By loading the image in a browser and inspecting it, you can see its intrinsic
 *    width and height. From there, compute the aspect ratio.
 *
 * It's recommended to determine and provide the most appropriate aspect ratio for optimal image presentation.
 *
 * Examples:
 *
 * Responsive image with 16:9 aspect ratio:
 * <NextImage src="/path/to/image.jpg" alt="A scenic view" aspectRatio={16/9} width={1600} />
 *
 * Image acting as background with overlay:
 * <NextImage src="/path/to/image.jpg" alt="A portrait">
 *   <span style={{ color: 'white', padding: '10px' }}>Overlay Text</span>
 * </NextImage>
 */

const NextImage: React.FC<NextImagePropTypes> = ({
  src,
  id,
  alt,
  children,
  className = '',
  aspectRatio,
  priority = true,
  position = 'relative',
  objectFit = 'cover',
  imgClassName = '',
  width = 100, // default width prop in pixels
  caption,
  style = {},
}) => {
  const computedHeight = width / (aspectRatio || 1);

  const imageProps = aspectRatio
    ? { width, height: computedHeight, layout: 'responsive', priority }
    : { layout: 'fill', objectFit, priority };


  return (
    <figure data-id={id} style={style} className={`${position} ${className} overflow-hidden overflow-y-hidden`}>
      <Image
        className={`!static object-center ${imgClassName}`}
        src={src}
        alt={alt ?? 'Image'}
        {...imageProps}
      />
      {children && (
        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>{children}</div>
      )}
      {caption && (
        <figcaption className='bottom-[0] z-[100] mt-[1.6rem] text-center text-[1.4rem] italic leading-[1.8rem] text-body-copy--dark 2xl:mx-[3rem]'>
          {caption}
        </figcaption>
      )}
    </figure>

  );
};

export default NextImage;
